:root {
	// --background: #220058;
	// --background-accent: #3c009c;
	// --foreground: #ffffff12;
	// --border-color: #c097ff;
	// --border-color-accent: #5d3b91;
	// --accent: #00ffff;
	// --text-color:#f3f3f3;

	// --primary-color: #fcb902;
	// --secondary-color: #b6b6b6;

	// --green: #00c711;

	--background: #1c1b23;
	--background-accent: #1e1d25;
	--foreground: #292732;
	--border-color-accent: #9793a1;

	--accent: #ee6d33;
	--text-color: #f3f3f3;
	--secondary-color: #9793a1;

	--green: #2d924c;
	--cyan: #40e0d0;
	--red: #ff5555;
	--disabled: #474747;
	--gold: #cfbd17;

	// DARK COLORS
	--dark-green: #006322;
	--dark-red: #c5162c;
	--dark-cyan: #00ad9f;
}
