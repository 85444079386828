@import "vars";
* {
    box-sizing: border-box;
}
body {
    margin: 0;
    color: var(--text-color);
    font-family: "Chakra Petch", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}
p{
    padding: 0;
    margin: 0;
}
h3 {
    font-size: 40px;
    line-height: 45px;
}
h4 {
    font-size: 32px;
    line-height: 36px;
}
a {
    color: inherit;
    text-decoration: none;
}
ul,
li {
    list-style: none;
    margin: 0;
    padding: 0;
}
.wrapper {
    background-color:var(--background);
    min-height: 100vh;
    min-width: 100%;
}
.container {
    max-width: 1230px;
    margin: 0 auto;
    padding: 0 16px;
}
.button {
    border-radius: 4px;
    padding: 20px 28px;
    border: none;
    color: var(--text-color);
    background-color: var(--green);
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    transition: all 0.2s;
    &:hover {
       background-color: var(--dark-green);
    }
    &:focus {
        background-color: var(--dark-green);
        outline: none;
    }
    &:focus-visible{
        background-color: var(--dark-green);
        outline: none;
    }
    &:disabled{
        background-color: var(--disabled);
        cursor: default;
        &:hover {
            background-color:var(--disabled);
         }
    }
    &.negative{
        background-color: var(--red);
    }
}
@import "home";
@import "event";
@import "reg";
@import "profile";
