.regForm {
	background-color: var(--foreground);
	padding: 56px;
	border-radius: 4px;
	margin-top: 120px;
	&__title {
		text-align: center;
		margin-bottom: 68px;
	}
	form {
		display: flex;
		flex-direction: column;
		>div {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}
	}
	input {
		width: 400px;
		background-color: var(--background-accent);
		border: none;
		border-radius: 4px;
		padding: 16px 24px;
		color: var(--text-color);
		border: 2px solid transparent;
		transition: all 0.2s;
		font-size: 24px;
		&::placeholder {
			color: var(--secondary-color);
		}
		&:focus {
			border: 2px solid var(--accent);
			outline: none;
		}
		&:focus-visible {
			border: 2px solid var(--accent);
			outline: none;
		}
		&:last-child {
			margin-bottom: 36px;
		}
	}
	&__error{
		color: var(--red);
		text-align: center;
	}
	&__btn {
		margin-top: 10px;
		padding: 18px 0;
		font-size: 24px;
		font-weight: 700;
		letter-spacing: 2px;
	}
	&__link {
		margin-top: 20px;
		text-align: center;
		font-size: 20px;
		a {
			color: var(--accent);
		}
	}
}
