.event {
	margin-top: 60px;
	&__inner {
		border-radius: 4px;
		background-color: var(--foreground);
		padding: 32px 32px 20px;
		margin-bottom: 44px;
	}
	&__info {
		display: flex;
		justify-content: space-between;
		padding-bottom: 32px;
		margin-bottom: 32px;
		border-bottom: 2px solid var(--border-color-accent);
		&-inner {
			display: flex;
			flex-direction: column;
			gap: 20px;
		}
	}
	&__title {
		font-size: 52px;
		line-height: 58px;
	}
	&__datetime {
		display: flex;
		gap: 20px;
		div {
			padding: 16px;
			background-color: var(--background-accent);
			border-radius: 2px;
		}
		span {
			color: var(--accent);
		}
	}
	&__prize {
		display: flex;
		flex-direction: column;
		gap: 20px;
		span {
			text-align: center;
			color: var(--accent);
			font-size: 60px;
			line-height: 65px;
			font-weight: 600;
		}
		button {
			padding: 12px 24px;
			border-radius: 4px;
		}
	}
	&__tabs {
		display: flex;
	}
	&__tab {
		text-transform: uppercase;
		padding: 16px 32px;
		&.disabled {
			span {
				cursor: default;
				color: var(--dark-red);
				border-bottom: 2px solid var(--dark-red);
				&:hover {
					color: var(--dark-red);
					border-bottom: 2px solid var(--dark-red);
				}
				&.active {
					color: var(--dark-red);
					border-bottom: 2px solid var(--dark-red);
				}
			}
		}
		& + & {
			border-left: 2px solid var(--border-color-accent);
		}
		span {
			cursor: pointer;
			padding-bottom: 34px;
			color: var(--secondary-color);
			border-bottom: 2px solid transparent;
			transition: all 0.2s;
			&:hover {
				color: var(--text-color);
				border-bottom: 2px solid var(--text-color);
			}
			&.active {
				color: var(--accent);
				border-bottom: 2px solid var(--accent);
			}
		}
	}
	&__overview {
		display: grid;
		grid-template-columns: 2fr 1fr;
		gap: 40px;
	}
	&__format {
		margin-bottom: 44px;
		&-title {
			margin-bottom: 32px;
		}
		&-items {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 40px;
		}
		&-item {
			background-color: var(--foreground);
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 32px;
			gap: 20px;
			border-radius: 4px;
			font-size: 24px;
			span {
				color: var(--accent);
				font-size: 16px;
				font-weight: 600;
			}
		}
	}
	&__information {
		display: flex;
		flex-direction: column;
		&-title {
			margin-bottom: 32px;
		}
		&-list {
			li {
				list-style: disc;
				margin-left: 20px;
				margin-bottom: 20px;
				color: var(--secondary-color);
			}
		}
	}
	&__participants {
		&-title {
			font-size: 32px;
			margin-bottom: 40px;
		}
		&-inner {
			padding: 32px;
			border-radius: 4px;
			background-color: var(--foreground);
			ul {
				display: flex;
				flex-direction: column;
				gap: 32px;
			}
			li {
				display: flex;
				justify-content: space-between;
				span {
					color: var(--accent);
					font-weight: 600;
				}
			}
		}
	}
	&__bracket {
		// h3 {
		// 	align-self: flex-start;
		// }
		display: flex;
		flex-direction: column;
		align-items: center;
		&-table {
			border: 2px solid var(--border-color-accent);
			border-bottom: 1px solid var(--border-color-accent);
			width: 600px;
			&-head {
				display: flex;
				border-bottom: 2px solid var(--border-color-accent);
				font-size: 28px;
				div {
					width: 50%;
					text-align: center;
					padding: 16px 0;
					color: var(--cyan);
					+ div {
						border-left: 1px solid var(--border-color-accent);
						color: var(--dark-red);
					}
				}
			}
			&-body {
				display: flex;
			}
			&-column {
				display: flex;
				width: 50%;
				flex-direction: column;
				// border-bottom: 1px solid var(--border-color-accent);
				& + & {
					border-left: 1px solid var(--border-color-accent);
					// .occupied {
					// 	color: var(--accent);
					// }
				}
				div {
					border-bottom: 1px solid var(--border-color-accent);
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					min-height: 50px;
					position: relative;
					&.empty {
						&::before {
							content: "JOIN";
							position: absolute;
							display: flex;
							justify-content: center;
							align-items: center;
							left: 0;
							right: 0;
							top: 0;
							bottom: 0;
							z-index: 5;
							background-color: var(--green);
							color: var(--text-color);
							opacity: 0;
							cursor: pointer;
							transition: all 0.2s;
						}
						&:hover {
							&::before {
								opacity: 1;
							}
						}
					}
					&.occupied.self {
						color: var(--accent);
						&::before {
							content: "LEAVE";
							position: absolute;
							display: flex;
							justify-content: center;
							align-items: center;
							left: 0;
							right: 0;
							top: 0;
							bottom: 0;
							z-index: 5;
							background-color: var(--red);
							color: var(--text-color);
							opacity: 0;
							cursor: pointer;
							transition: all 0.2s;
						}
						&:hover {
							&::before {
								opacity: 1;
							}
						}
					}
					&.occupied {
						color: var(--accent);
						&::before {
							display: none;
						}
					}
				}
			}

			// &-row {
			// 	display: flex;
			// 	border-bottom: 1px solid var(--border-color-accent);
			// 	div {
			// 		width: 50%;
			// 		border-bottom: 1px solid var(--border-color-accent);
			// 		display: flex;
			// 		justify-content: center;
			// 		align-items: center;
			// 		min-height: 50px;
			// 		position: relative;
			// 		& + div {
			// 			border-left: 1px solid var(--border-color-accent);
			// 		}
			// 		&.empty {
			// 			&::before {
			// 				content: "JOIN";
			// 				position: absolute;
			// 				display: flex;
			// 				justify-content: center;
			// 				align-items: center;
			// 				left: 0;
			// 				right: 0;
			// 				top: 0;
			// 				bottom: 0;
			// 				z-index: 5;
			// 				background-color: var(--green);
			// 				opacity: 0;
			// 				cursor: pointer;
			// 				transition: all 0.2s;
			// 			}
			// 			&:hover {
			// 				&::before {
			// 					opacity: 1;
			// 				}
			// 			}
			// 		}
			// 		&.occupied {
			// 			&::before {
			// 				content: "LEAVE";
			// 				position: absolute;
			// 				display: flex;
			// 				justify-content: center;
			// 				align-items: center;
			// 				left: 0;
			// 				right: 0;
			// 				top: 0;
			// 				bottom: 0;
			// 				z-index: 5;
			// 				background-color: var(--red);
			// 				opacity: 0;
			// 				cursor: pointer;
			// 				transition: all 0.2s;
			// 			}
			// 			&:hover {
			// 				&::before {
			// 					opacity: 1;
			// 				}
			// 			}
			// 		}
			// 	}
			// }
			// &-body {
			// 	display: grid;
			// 	grid-template-columns: 1fr 1fr;
			// 	div {
			// 		border-bottom: 1px solid var(--border-color-accent);
			// 		display: flex;
			// 		justify-content: center;
			// 		align-items: center;
			// 		min-height: 50px;
			// 		position: relative;
			// 		&:nth-child(2n) {
			// 			border-left: 1px solid var(--border-color-accent);
			// 		}
			// 		&.empty {
			// 			&::before {
			// 				content: "JOIN";
			// 				position: absolute;
			// 				display: flex;
			// 				justify-content: center;
			// 				align-items: center;
			// 				left: 0;
			// 				right: 0;
			// 				top: 0;
			// 				bottom: 0;
			// 				z-index: 5;
			// 				background-color: var(--green);
			// 				opacity: 0;
			// 				cursor: pointer;
			// 				transition: all 0.2s;
			// 			}
			// 			&:hover {
			// 				&::before {
			// 					opacity: 1;
			// 				}
			// 			}
			// 		}
			// 		&.occupied {
			// 			&::before {
			// 				content: "LEAVE";
			// 				position: absolute;
			// 				display: flex;
			// 				justify-content: center;
			// 				align-items: center;
			// 				left: 0;
			// 				right: 0;
			// 				top: 0;
			// 				bottom: 0;
			// 				z-index: 5;
			// 				background-color: var(--red);
			// 				opacity: 0;
			// 				cursor: pointer;
			// 				transition: all 0.2s;
			// 			}
			// 			&:hover {
			// 				&::before {
			// 					opacity: 1;
			// 				}
			// 			}
			// 		}
			// 	}
			// }
		}
	}
}
