.profile {
	&__tabs {
		display: flex;
		margin-bottom: 48px;
		justify-content: center;
		margin-top: 12px;
	}
	ul {
		max-width: 800px;
		width: 100%;
		display: flex;
		flex-direction: column;

		gap: 20px;
	}
	&__inner{
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
	&__user {
		border: 1px solid var(--border-color-accent);
		padding: 20px;
		font-size: 32px;
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		background-color: var(--foreground);
		span {
			color: var(--gold);
		}
	}
	&__pay {
		display: flex;
		flex-direction: column;
		gap: 16px;
		border-radius: 4px;
		background-color: var(--foreground);
		border: 1px solid var(--border-color-accent);
		padding: 20px;
		span:first-child {
			color: var(--gold);
		}
	}
	&__input {
		display: flex;
		input {
			width: 400px;
			background-color: var(--background-accent);
			border: none;
			border-radius: 4px 0 0 4px;
			padding: 16px 24px;
			color: var(--text-color);
			border: 2px solid transparent;
			transition: all 0.2s;
			font-size: 24px;
			&::placeholder {
				color: var(--secondary-color);
			}
			&:focus {
				border-top: 2px solid var(--accent);
				border-bottom: 2px solid var(--accent);
				border-left: 2px solid var(--accent);
				outline: none;
			}
			&:focus-visible {
				border-top: 2px solid var(--accent);
				border-bottom: 2px solid var(--accent);
				border-left: 2px solid var(--accent);
				outline: none;
			}
		}
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0; 
		}

		input[type="number"] {
			-moz-appearance: textfield;
		}
		.button {
			border-radius: 0 4px 4px 0;
			margin-left: -3px;
		}
	}
	&__transaction {
		border: 1px solid var(--border-color-accent);
		border-radius: 4px;
		&-top {
			padding: 20px;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid var(--border-color-accent);
		}
		&-operation {
			&.plus {
				span {
					color: var(--green);
					font-weight: 600;
				}
			}
			&.minus {
				span {
					color: var(--red);
					font-weight: 600;
				}
			}
		}
		&-sum {
			padding: 20px;
			span {
				color: var(--gold);
			}
		}
	}
}
