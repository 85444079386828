.header {
    font-family: "Roboto", sans-serif;
    background-color: var(--foreground);
    // border-bottom: 1px solid var(--text-color);
    position: relative;
    top: 0;
    z-index: 1000;
    &__inner {
        display: flex;
        padding: 12px 0;
        position: relative;
        gap: 400px;
        align-items: center;
    }
    &__menu {
        display: flex;
        gap: 20px;
        li {
            transition: all 0.2s;
            &:hover {
                color: var(--accent);
            }
        }
    }
}
.login {
    position: absolute;
    right: 0;
}
.descr {
    font-family: "Roboto", sans-serif;
    &__inner {
        display: flex;
        justify-content: center;
    }
    &__title {
        text-align: center;
        font-size: 52px;
        text-transform: uppercase;
        margin: 60px 0 72px;
        span {
            color: var(--accent);
            font-family: "Chakra Petch", sans-serif;
        }
    }
}
.tournaments {
    &__title {
        font-family: "Roboto", sans-serif;
        text-align: center;
        font-size: 40px;
        line-height: 45px;
        margin-bottom: 24px;
    }
    &__items {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px;
        button {
        }
    }
    &__form {
        align-self: center;
        display: flex;
        input {
            width: 350px;
            background-color: var(--foreground);
            border: none;
            border-radius: 4px 0 0 4px;
            padding: 16px 24px;
            color: var(--text-color);
            border: 2px solid transparent;
            transition: all 0.2s;
            font-size: 24px;
            &::placeholder {
				color: var(--secondary-color);
                font-size: 20px;
			}
			&:focus {
				border-top: 2px solid var(--accent);
				border-bottom: 2px solid var(--accent);
				border-left: 2px solid var(--accent);
				outline: none;
			}
			&:focus-visible {
				border-top: 2px solid var(--accent);
				border-bottom: 2px solid var(--accent);
				border-left: 2px solid var(--accent);
				outline: none;
			}
        }
        input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0; 
		}

		input[type="number"] {
			-moz-appearance: textfield;
		}
        .button{
            border-radius: 0 4px 4px 0;
            margin-left: -3px;
        }
    }
    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--foreground);
        padding: 32px;
        // border: 1px solid var(--border-color);
        border-radius: 4px;
    }
    &__item-img {
        max-width: 200px;
        width: 100%;
        height: 230px;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    &__item-name {
        font-size: 25px;
        line-height: 45px;
        margin-bottom: 20px;
    }
    &__item-datetime {
        display: flex;
        gap: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 2px solid var(--border-color-accent);
        div {
            padding: 16px;
            background-color: var(--background-accent);
            border-radius: 8px;
        }
        span {
            color: var(--accent);
        }
    }
    &__item-bottom {
        display: flex;
        padding: 0;
        gap: 20px;
        margin: 0;
        font-size: 16px;
        li {
            text-align: center;
            padding: 16px;
            background-color: var(--background-accent);
            // border: 1px solid var(--border-color-accent);
            border-radius: 8px;
            div {
                margin-bottom: 12px;
            }
            span {
                color: var(--accent);
                font-weight: 600;
            }
        }
    }
    &__item-prize {
        display: flex;
        gap: 20px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        align-content: center;
        padding: 20px;
        border-radius: 4px;
        border: 2px solid var(--border-color-accent);
        &-money {
            font-size: 40px;
            font-weight: 600px;
        }
        span {
            color: var(--green);
        }
    }
}
